import {Injectable, Inject} from '@angular/core';
import {Observable} from 'rxjs';

import {switchMap} from 'rxjs/operators';
import {MediaListResponse} from '../models/media/media-list-response';
import {BaseClient} from './base-client.service';
import { TimezoneModel } from '../models/organizations/timezones';

@Injectable()
export class TimezoneClient extends BaseClient {

  public getBaseEndpoint() {
    return this.baseEndpoint + '/timezones';
  }

  public list(): Observable<Array<TimezoneModel>> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.get<Array<TimezoneModel>>(this.getBaseEndpoint(),
        {headers: this.getHeaders(token)});
    }));
  }
}

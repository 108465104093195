import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import { AdminConfigClient } from './adminconfig-client.service';
import { ListDataSource } from './list-data-source';
import { UserClient } from './user-client.service';
import { CustomerClient } from './customers-client.service';
import { SettingClient } from './setting-client.service';
import { FaqClient } from './faq-client.service';
import { SupportClient } from './support-client.service';
import { DashboardClient } from './dashboard-client.service';
import { MediaClient } from './media-client.service';
import { CommentClient } from './comment-client.service';
import { BannerClient } from './banner-client.service';
import { CategoryClient } from './category-client.service';
import { EpisodeClient } from './episode-client.service';
import { PaymentmethodClient } from './paymentmethod-client.service';
import { AuthorClient } from './author-client.service';
import { PaymentPlansClient } from './payment-plans-client.service';
import { OrganizationClient } from './organization-client.service';
import { TimezoneClient } from './timezone-client.service';

const SERVICES = [
  ListDataSource,
  AdminConfigClient,
  UserClient,
  CustomerClient,
  SettingClient,
  FaqClient,
  SupportClient,
  DashboardClient,
  MediaClient,
  CommentClient,
  BannerClient,
  CategoryClient,
  EpisodeClient,
  PaymentmethodClient,
  AuthorClient,
  PaymentPlansClient,
  OrganizationClient,
  TimezoneClient
];

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ...SERVICES,
  ],
})
export class NetworkModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: NetworkModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}

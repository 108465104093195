import {Injectable, Inject} from '@angular/core';
import {Observable} from 'rxjs';

import {switchMap} from 'rxjs/operators';
import {MediaListResponse} from '../models/media/media-list-response';
import {BaseClient} from './base-client.service';
import { Plans } from '../models/payment-plans/plans';

@Injectable()
export class PaymentPlansClient extends BaseClient {

  public getBaseEndpoint() {
    return this.baseEndpoint + '/plans';
  }

  public getBaseEndpointV1() {
    return this.baseEndpoint + '/plan';
  }

  public list(): Observable<MediaListResponse> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.get<MediaListResponse>(this.getBaseEndpoint(),
        {headers: this.getHeaders(token)});
    }));
  }

  public show(id: string): Observable<Plans> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.get<Plans>(this.getBaseEndpoint() + '/' + id,
        {headers: this.getHeaders(token)});
    }));
  }

  public store(formData: FormData): Observable<Plans> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.post<Plans>(this.getBaseEndpointV1()+"/store", formData,
        {headers: this.getHeaders(token, false)});
    }));
  }

  public update(id, formData: FormData): Observable<Plans> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.post<Plans>(this.getBaseEndpointV1()+"/update" + '/' + id, formData,
        {headers: this.getHeaders(token, false)});
    }));
  }
}
